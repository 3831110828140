import React from 'react';
import { st, classes } from './NoAvailableSlots.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import {
  Text,
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
  TextButton,
  ButtonSize,
  Button,
} from 'wix-ui-tpa';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { NoAvailableSlotsViewModel } from '../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { DataHooks } from './constants';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { ReactComponent as AlertIcon } from '../../../../assets/Icons/notificationAlert.svg';

export type NoAvailableSlotsProps = {
  viewModel: NoAvailableSlotsViewModel;
};

const NoAvailableSlots: React.FC<NoAvailableSlotsProps> = ({ viewModel }) => {
  const { isMobile } = useEnvironment();
  const {
    noSessionsOfferedText,
    noUpcomingTimeSlotsText,
    checkAvailabilityCTA,
  } = viewModel;

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={DataHooks.NoAvailableSlots}
    >
      <Text
        data-hook={DataHooks.Text}
        className={st(classes.noAvailableSlots)}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {noSessionsOfferedText}
      </Text>
      {checkAvailabilityCTA ? (
        <CheckAvailabilityCTA isFullWidth={checkAvailabilityCTA.isFullWidth}>
          {checkAvailabilityCTA.text}
        </CheckAvailabilityCTA>
      ) : null}
      {noUpcomingTimeSlotsText ? (
        <SectionNotification
          className={classes.notification}
          data-hook={DataHooks.Notification}
          type={NOTIFICATION_TYPE.alert}
        >
          <SectionNotification.Icon icon={<AlertIcon />} />
          <SectionNotification.Text>
            {noUpcomingTimeSlotsText}
          </SectionNotification.Text>
        </SectionNotification>
      ) : null}
    </div>
  );
};

const CheckAvailabilityCTA: React.FC<{ isFullWidth: boolean }> = ({
  isFullWidth,
  children,
}) => {
  const { experiments } = useExperiments();
  const { goToNextAvailableDate } = useCalendarActions();
  const isNextAvailableDesignImprovementsEnabled = experiments.enabled(
    'specs.bookings.NextAvailableDesignImprovements',
  );

  return isNextAvailableDesignImprovementsEnabled ? (
    <Button
      data-hook={DataHooks.CTA}
      className={classes.cta}
      size={ButtonSize.medium}
      onClick={() => goToNextAvailableDate()}
      fullWidth={isFullWidth}
      upgrade
    >
      {children}
    </Button>
  ) : (
    <TextButton
      data-hook={DataHooks.Link}
      className={classes.nextAvailableDateLink}
      onClick={() => goToNextAvailableDate()}
    >
      {children}
    </TextButton>
  );
};

export default NoAvailableSlots;
