import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { TextButton } from 'wix-ui-tpa';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeftSmall';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRightSmall';
import { classes, st } from './BackButton.st.css';
import { DataHooks } from './constants';
import { useHistory } from '../../Hooks/useHistory/useHistory';

export interface BackButtonProps {}

export const BackButton: React.FC<BackButtonProps> = () => {
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const { back, length } = useHistory();

  const isBackButtonExperimentEnabled = experiments.enabled(
    'specs.bookings.calendarBackButton',
  );
  const showBackButton = isBackButtonExperimentEnabled && length > 1;

  if (!showBackButton) {
    return null;
  }

  return (
    <div className={st(classes.root, { isRTL })}>
      <TextButton
        data-hook={DataHooks.BackButton}
        onClick={back}
        className={classes.button}
        prefixIcon={
          isRTL ? <ChevronRight aria-hidden /> : <ChevronLeft aria-hidden />
        }
      >
        {t('app.calendar.back')}
      </TextButton>
    </div>
  );
};
