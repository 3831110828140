import {
  createSettingsParam,
  createSettingsParams,
} from '@wix/yoshi-flow-editor/tpa-settings';
import calendarSettingsParams from '../BookingCalendar/settingsParams';
import { LayoutOptions } from '../../types/types';

const widgetLayout = createSettingsParam('calendarLayout', {
  getDefaultValue: ({ isMobile }) =>
    isMobile
      ? LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER
      : LayoutOptions.WEEKLY_TIMETABLE,
});

const weeklyTimetableSettingsParams = createSettingsParams<{
  calendarLayout: LayoutOptions;
}>({
  calendarLayout: widgetLayout as any,
});

export default {
  ...calendarSettingsParams,
  ...weeklyTimetableSettingsParams,
};
