import React from 'react';
import calendarSettingsParams from '../settingsParams';
import weeklyTimetableSettingsParams from '../../WeeklyTimetable/settingsParams';
import { Preset } from '../../../types/types';

export const PresetContext = React.createContext<Preset>(Preset.CALENDAR_PAGE);

export const PresetConsumer = PresetContext.Consumer;
export const PresetProvider = PresetContext.Provider;

export const useSettingsParams = () => {
  const preset = React.useContext(PresetContext);

  if (preset === Preset.WEEKLY_TIMETABLE_WIDGET) {
    return weeklyTimetableSettingsParams;
  }

  return calendarSettingsParams;
};
