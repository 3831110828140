import React from 'react';
import { classes } from './DailyLayout.st.css';
import TimePicker from './TimePicker/TimePicker';
import DatePicker from './DatePicker/DatePicker';
import SectionHeader from '../SectionHeader/SectionHeader';
import ErrorNotification from '../ErrorNotification/ErrorNotification';
import TimezoneSelection from '../TimezoneSelection/TimezoneSelection';
import { DailyTimeSlotsMonthlyPickerLayoutViewModel } from '../../ViewModel/bodyViewModel/bodyViewModel.types';
import { DataHooks } from './constants';

export interface DailyLayoutProps {
  viewModel: DailyTimeSlotsMonthlyPickerLayoutViewModel;
  errorNotificationText: string;
}

const DailyLayout: React.FC<DailyLayoutProps> = ({
  viewModel: {
    bodyTitle,
    datePickerViewModel,
    timePickerViewModel,
    timezoneSelectionViewModel,
  },
  errorNotificationText,
}) => {
  return (
    <div data-hook={DataHooks.DailyLayout}>
      <SectionHeader
        title={bodyTitle}
        suffix={
          <TimezoneSelection
            viewModel={timezoneSelectionViewModel}
            dataHook={DataHooks.Timezone}
          />
        }
      />
      <ErrorNotification errorText={errorNotificationText} />
      <div className={classes.root}>
        <DatePicker datePickerViewModel={datePickerViewModel!} />
        <TimePicker viewModel={timePickerViewModel!} />
      </div>
    </div>
  );
};

export default DailyLayout;
